import React, {Component} from "react"
import {Link, graphql} from "gatsby"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

class NewsPage extends Component {
  render() {
    const data = this.props.data
    const blogs = data.wordpress.edges.filter(function(e) {
      return typeof e.node.categories.find( c => c.name === "News" ) != "undefined"
    });
    const contentfulBlogs = data.contentful.edges;
    return (
      <Layout isAlternate={true}>
        <SEO title="News" description="Stay up to date on user stories, shared smiles, nutrition, science and well-being. Read our articles at ReasonToSmile.com." />
        <section className="hero is-large has-yellow-background has-absolute-navbar" style={{backgroundImage:"url(https://reasontosmile.wpengine.com/wp-content/uploads/2019/10/BLOG_2x.jpg)", backgroundSize:"cover"}}>
          <div className="hero-body">

            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered has-text-light">

                  <h1 className="title page-title has-text-light">
                    Smile News
                  </h1>
                  <h3>Articles on user stories, shared smiles, nutrition, science and well-being.</h3>
                </div>

              </div>
            </div>
          </div>

        </section>
        <section className="section">
          <div className="container">
            {contentfulBlogs.map(({node}, index) => (
              node.slug !== "static-sites-are-great" ?
              <Link to={`/news/${node.slug}`} style={{
                textDecoration: `none`
              }}>
                <div className={`columns is-centered is-vcentered` + (index % 2 ? " is-reversed" : "")} key={node.id}>

                  <div className="column is-5">

                    <Img
                    alt={node.title}
                    fluid={node.heroImage.fluid}
                    />
                  </div>
                  <div className="column is-5">
                    <p className="has-text-danger has-text is-uppercase has-text-weight-bold">{node.category}</p>
                    <br/>
                    <h2 className="has-text-black">{node.title}</h2>
                    <div className="has-text-grey-dark is-size-4">
                      <p>{node.body.childMarkdownRemark.excerpt}</p>
                      </div>
                  </div>
                </div>
              </Link>
              :
              false

            ))}
            {blogs.map(({node}, index) => (
              <Link to={`/news${node.path}`} style={{
                textDecoration: `none`
              }}>
                <div className={`columns is-centered is-vcentered` + ((contentfulBlogs.length - 1 + index) % 2 ? " is-reversed" : "")} key={node.id}>

                  <div className="column is-5">

                    {node.featured_media && <img src={node.featured_media.source_url} alt={node.featured_media.alt_text}/>
}

                  </div>
                  <div className="column is-5">
                    <p className="has-text-danger has-text is-uppercase has-text-weight-bold">{node.categories[1].name !== "News" ? node.categories[1].name: node.categories[0].name}</p>
                    <br/>
                    <h2 className="has-text-black" dangerouslySetInnerHTML={{
                      __html: node.title
                    }}/>
                    <div className="has-text-grey-dark is-size-4" dangerouslySetInnerHTML={{
                      __html: node.excerpt
                    }}/>
                  </div>
                </div>
              </Link>

            ))}

          </div>
        </section>

      </Layout>
    )
  }
}

export default NewsPage

// Set here the ID of the home page.
export const pageQuery = graphql `
  query {
    wordpress: allWordpressPost {
      edges {
        node {
          title
          excerpt
          slug
          path
          featured_media {
              id
              source_url
              alt_text
            }
          categories {
            name
          }
        }
      }
    }
    contentful: allContentfulBlogPost {
      edges {
        node {
          title
          category
          slug
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(format: PLAIN, truncate: false)
              timeToRead
            }
          }
        }
      }
    }
  }
`
